// @flow
// test

import { Media } from "@2po-dgp/components";
import { SimpleCard } from "@components";
import { Analytics } from "@containers";
import { Router } from "@reach/router";
import { PAGE_TYPES } from "@utils/Constants";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Logo from "../../images/logo_Degroof_Petercam.inline.svg";
import { notFound, notFoundCard } from "../../utils/Translations";
import * as style from "./style.module.scss";

const Body = () => {
    const language = "en";
    const code = "be";

    return (
        <Analytics.PageWrapper key={404} type={PAGE_TYPES.NOTFOUND}>
            <Container className={style.navbar}>
                <Media
                    imageAlt="Degroof Petercam logo"
                    imageSrc={() => <Logo />}
                />
            </Container>
            <Container className={style.content}>
                <Row>
                    <Col xs={12}>
                        <div className={style.hero}>
                            <h1>{notFound(language, "title")}</h1>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: notFound(language, "text"),
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col key={0} md={6}>
                        <SimpleCard
                            image={() => (
                                <StaticImage
                                    alt="404"
                                    src="../../images/404-card1.jpg"
                                    aspectRatio={1.5}
                                    layout="fullWidth"
                                />
                            )}
                            title={notFoundCard(language, 0, "title")}
                            text={notFoundCard(language, 0, "text")}
                            buttonText={notFoundCard(language, 0, "buttonText")}
                            buttonUrl={language && `/${language}-${code}`}
                            icon={"arrowRight"}
                        />
                    </Col>
                    <Col key={1} md={6}>
                        <SimpleCard
                            image={() => (
                                <StaticImage
                                    alt="404"
                                    src="../../images/404-card2.jpg"
                                    aspectRatio={1.5}
                                    layout="fullWidth"
                                />
                            )}
                            title={notFoundCard(language, 1, "title")}
                            text={notFoundCard(language, 1, "text")}
                            buttonText={notFoundCard(language, 1, "buttonText")}
                            buttonUrl={
                                language && `/${language}-${code}/contact`
                            }
                            icon={"arrowRight"}
                        />
                    </Col>
                </Row>
            </Container>
        </Analytics.PageWrapper>
    );
};

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
    return (
        browser && (
            <Router>
                <Body path="/:locale/:url" />
                <Body path="/:locale/" />
            </Router>
        )
    );
};

export default NotFoundPage;
